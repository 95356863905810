import { GameConfig, printConfig, _defaultConfig } from "./config";
import { IFallingObject } from "./falling-object2";
import { GameStarter } from "./game";

let variables: any = {};

const defaultFallingObject: IFallingObject = {
	doCatch: true,
	horizontalSpeed: "0",
	verticalSpeed: "500",
	startRotation: "0",
	scale: "1",
	collisionScale: "1",
	sprite: "",
	index: -1,
	points: 1,
	circle: true,
	changeOfSpawning: 1,
	rotationSpeed: 0,
	zIndex: "0"
};

const customConfig: any = getSettingsFromURI(location.search);

let config: any = _defaultConfig;

if (customConfig)
	for (const [key, value] of Object.entries(customConfig)) {
		config[key] = value;
	}

// printConfig(_defaultConfig);

let c = config as GameConfig;
config.objects = [
	...parseFallingObjects(config.doCatch || "", true),
	...parseFallingObjects(config.doNotCatch || "", false),
];


if (c.doCatchSprite && c.spawnDoCatch) {
	const doCatch = {...defaultFallingObject};
	doCatch.collisionScale = c.doCatchCollisionScale.toString();
	doCatch.doCatch = true;
	doCatch.horizontalSpeed =  `${c.minVelocityHorizontal}-${c.maxVelocityHorizontal}`,
	doCatch.verticalSpeed = `${c.minVelocityVertical}-${c.maxVelocityVertical}`,
	doCatch.startRotation = "0",
	doCatch.scale =  c.doCatchScale.toString(),
	doCatch.sprite = c.doCatchSprite,
	doCatch.points = c.catchPoints,
	config.objects.push(doCatch);
}

if (c.doNotCatchSprite && c.spawnDoNotCatch) {
	const doNotCatch = {...defaultFallingObject}
	doNotCatch.collisionScale = c.doNotCatchCollisionScale
			? c.doNotCatchCollisionScale.toString()
			: c.doCatchCollisionScale.toString()
			doNotCatch.doCatch= false
			doNotCatch.horizontalSpeed= `${c.minVelocityHorizontal}-${c.maxVelocityHorizontal}`
			doNotCatch.verticalSpeed= `${c.minVelocityVertical}-${c.maxVelocityVertical}`
			doNotCatch.startRotation= "0"
			doNotCatch.scale= c.doNotCatchScale
			? c.doNotCatchScale.toString()
			: c.doCatchScale.toString()
			doNotCatch.sprite= c.doNotCatchSprite,
			doNotCatch.points= c.doNoCatchPoints,
	config.objects.push(doNotCatch)
}

config.objects.map((o, i) => {
	o.index = i;
});

console.log(config);


const game = new GameStarter(<GameConfig>config);


function getSettingsFromURI(uri: string) {

	let settingString;
	const split = uri.split("&");
	for (let s of split) {
		if (s[0] === "s") {
			settingString = atob(s.split("=")[1]);
		}
	}

	if (settingString === undefined) {
		return null;
	}

	let jsonString = "{";
	let settings: string[] = settingString.split("\n");

	settings.forEach((s) => {
		const keyAndValue = s.split("=");
		if (keyAndValue.length === 1) {
			return;
		}

		const key = keyAndValue[0].trim();
		const value = keyAndValue[1].trim();

		if (value === "default" || key === "cache") {
			return;
		}

		let line = '"' + key + '": ';

		if(key.includes("var")){
			console.log(key);
			const varname = key.trim().split(" ")[1].trim();
			
			variables[varname] = value.trim();
		} else {
			if (isString(value)) {
				line += '"' + value + '"';
			} else {
				line += value;
			}
			if (settings.indexOf(s) < settings.length - 2) {
				line += ",\n";
			}
	
			jsonString += line;
		}
	});

	jsonString += "}";

	Object.keys(variables).forEach(key => {
		const value = variables[key];

		if(value === 'true' || value === 'false' || !isString(value)){
			const regex = new RegExp(`"${key}"`, 'g')
			jsonString = jsonString.replace(regex,  value);
		}


		const regex = new RegExp(`${key}`, 'g')
		jsonString = jsonString.replace(regex, value)
			
		
	})

	return JSON.parse(jsonString);
}

function parseFallingObjects(value: string, doCatch: boolean) {
	const objects = value.split("}");

	let parsed: IFallingObject[] = [];

	const props = [
		"sprite",
		"horizontalSpeed",
		"verticalSpeed",
		"scale",
		"collisionScale",
		"startRotation",
		"rotationSpeed",
		"points",
		"changeOfSpawning",
		"zIndex"
	];

	objects.forEach((o, i) => {
		o = o.trim();
		if (o.length === 0) {
			return parsed;
		}

		let object = { ...defaultFallingObject };
		let properties = o.split("{")[1].split(",");

		properties.forEach((property, i) => {
			if (props[i] === "startRotation") {
				if(property.trim() === "true"){
					object[props[i]] = "0-360";
				}
				else if(property.trim() === "false"){
					object[props[i]] = "0"
				}
				else {
					object[props[i]] = property;
				}
			} else if (props[i] === "points" || props[i] === "rotationSpeed") {
				object[props[i]] = +property;
			} else {
				object[props[i]] = property.trim();
			}
		});

		object.doCatch = doCatch;

		object.index = i;

		for(let i = 0; i < object.changeOfSpawning; i++){
			parsed.push(object);
		}

	});

	return parsed;
}

function isString(value: string) {
	if (!isNaN(Number(value)) || value === "true" || value === "false") {
		return false;
	}
	return true;
}
