import Phaser, { Game } from "phaser";
import { Catcher } from "./catcher";
import { fonts, GameConfig } from "./config";
import { FallingObject2 } from "./falling-object2";
import { Platform } from "./Platform";
import WebFontFile from "./webfontload";

export class GameStarter {
	imageLoadCounter = 1;
	config: GameConfig;
	path: string;
	width: number;
	height: number;

	constructor(config: GameConfig) {
		this.width = window.innerWidth * window.devicePixelRatio;
		this.height = window.innerHeight * window.devicePixelRatio;
		this.config = config;
		this.path = config.path;

		document.addEventListener("DOMContentLoaded", () => {
			this.loadImages();
		});
	}

	loadImages() {
		this.loadImage("catcher", this.config.catcherSprite);

		const unique = [...new Set(this.config.objects)];

		this.imageLoadCounter += unique.length;

		unique.forEach((object, i) => {
			this.loadImage(object.sprite, object.sprite);
		})

		if (this.config.heartSprite){
			this.imageLoadCounter += 1;
			this.loadImage("heart", this.config.heartSprite);
		}
		
	}

	loadImage(key: string, src: string) {
		let img = new Image();
		img.id = key;
		img.hidden = true;
		img.onload = () => {
			document.body.append(img);
			this.imageReady();
		};
		img.onerror = () => {
			console.error("Img " + key + " with source " + src + " not found");
			document.body.append(img);
			this.imageReady();
		};
		img.src = this.path + src;
	}

	init() {
		const container = document.getElementById("container");
		const phaserConfig: Phaser.Types.Core.GameConfig = {
			type: Phaser.CANVAS,
			width: this.width,
			height: this.height,
			parent: container ? container : undefined,
			scene: [new CatchGame(this.config, this.width, this.height)],
			render: {
				transparent: true,
			},
			physics: {
				default: "arcade",
				arcade: {
					debug: CatchGame.config.debug,
					debugShowVelocity: false,
				},
			},
			audio: {
				noAudio: true,
			},
			scale: {
				mode: Phaser.Scale.FIT,
			},
			fps: {
				smoothStep: true,
			},
		};
		const game = new Game(phaserConfig);
	}

	imageReady() {
		this.imageLoadCounter--;
		if (this.imageLoadCounter === 0) {
			this.init();
		}
	}
}

export class CatchGame extends Phaser.Scene {
	static config: GameConfig;
	static width: number;
	static height: number;

	screenHasBeenTapped: boolean = false;
	splashImage: Phaser.GameObjects.Image;

	platform: Platform;
	fallingObjects: FallingObject2[] = [];
	colliders: FallingObject2[] = []

	spawnTimer: number = 0;
	speedIncreaseTimer: number = 0;
	speed: number = 1;

	score: number = 0;

	maxObjects: number;
	objectCountIncreaseTimer: number = 0;

	playing: boolean = false;

	lives: number = 0;

	catcherEnabled: boolean = true;

	splashScreen: Phaser.GameObjects.DOMElement;

	constructor(config: GameConfig, width: number, height: number) {
		super({
			// physics: {
			//     default: 'arcade',
			//     arcade: {
			//     }
			// },
		});
		CatchGame.config = config;
		this.platform = new Platform();
		this.lives = CatchGame.config.lives;

		CatchGame.width = Math.round(width);
		CatchGame.height = Math.round(height);
		this.maxObjects = CatchGame.config.minFallingObjects;
	}

	particles: Phaser.GameObjects.Particles.ParticleEmitterManager[] = []

	preload() {
		this.load.path = CatchGame.config.path;

		this.load.addFile(new WebFontFile(this.load, CatchGame.config.font));

		const catcher = <HTMLImageElement>document.getElementById("catcher");
		this.textures.addImage("catcher", catcher);
		
		if(CatchGame.config.objects){
			new Set(CatchGame.config.objects).forEach((object, i) => {
				const img = <HTMLImageElement>(
					document.getElementById(object.sprite)
				);
				this.textures.addImage(object.sprite, img);
				// document.body.removeChild(img);
			})
		}


		if (CatchGame.config.heartSprite) {
			const heart = <HTMLImageElement>document.getElementById("heart");
			this.textures.addImage("heart", heart);
			// document.body.removeChild(heart);
		}
	}

	catcher?: Catcher;

	create() {
		this.platform.preloadSounds(CatchGame.config);
		this.scale.displaySize.resize(CatchGame.width, CatchGame.height);

		CatchGame.config.objects.forEach((object, i) => {
			const p = this.add.particles(`object${i}`)
			this.particles.push(p);
		})

		

		this.catcher = new Catcher(this);
		this.catcher.reset();

		this.physics.world.setBoundsCollision(true, true, false, false);

		if (CatchGame.config.bounce)
			this.physics.add.collider(
				this.colliders,
				this.colliders,
				() => {
					this.playSound("bounce");
				},
				undefined,
				CatchGame
			);

		this.physics.world.on(
			"worldbounds",
			(body: Phaser.Physics.Arcade.Body) => {
				if (this.isGameOver) {
					return;
				}

				if (body.y > 0 && body.y < CatchGame.height)
					this.playSound("wallBounce");
			}
		);

		this.physics.add.overlap(
			this.catcher,
			this.fallingObjects,
			(catcher, faller) => this.handleCatch(faller)
		);

		this.input.on(
			"pointermove",
			(pointer: any) => {
				if (this.catcher && this.catcherEnabled && this.playing) {
					this.catcher.updatePosition(pointer.x);
				}
			},
			this
		);

		this.input.on(
			"pointerup",
			() => {
				if (this.isGameOver) {
					return;
				}
				this.startGame();
			},
			this
		);

		let splashScreen = document.getElementById("image") as HTMLImageElement;
		if (splashScreen) {
			splashScreen.src =
				CatchGame.config.path + "/" + CatchGame.config.splashSprite;

			splashScreen.style.width = window.innerWidth + "px";
			splashScreen.style.top =
				(window.innerHeight * CatchGame.config.splashY) / 100 + "px";
			splashScreen.style.transform =
				"translateY(-50%) scale(" + CatchGame.config.splashScale + ")";
		}

		this.platform.init(this);
	}

	update(time: number, delta: number): void {
		if (this.playing === false) {
			return;
		}

		if (
			CatchGame.config.scoreType === "time" ||
			CatchGame.config.scoreType === "timeandpoints"
		) {
			this.updateScore(delta * CatchGame.config.bonusPerMs);
		}

		this.spawnTimer += delta;
		this.speedIncreaseTimer += delta;
		this.objectCountIncreaseTimer += delta;

		if (this.speedIncreaseTimer > CatchGame.config.speedIncreaseInterval) {
			this.speedIncreaseTimer = 0;
			this.speed += CatchGame.config.speedIncrease;
		}

		if (
			this.objectCountIncreaseTimer >
				CatchGame.config.objectIncreaseInterval &&
			this.maxObjects < CatchGame.config.maxFallingObjects
		) {
			this.maxObjects += 1;
			this.spawnFallingObject();
			this.objectCountIncreaseTimer = 0;
		}

		this.fallingObjects.forEach((f) => {
			f.update();
		});
	}

	pause() {
		this.physics.pause();
		this.playing = false;
		this.catcherEnabled = false;
		this.fallingObjects.forEach((f) => {
			f.pause();
		});
	}

	resume() {
		if (this.lives > 0) {
			this.catcherEnabled = true;
			this.playing = true;
			this.fallingObjects.forEach((f) => f.resume());
			this.physics.resume();
			return;
		}
		this.gameOver();
	}

	play() {
		this.isGameOver = false;
	}

	startGame() {
		if (this.playing || this.isGameOver) {
			return;
		}
		if (this.screenHasBeenTapped) {
			return;
		}

		this.platform.sendLives(this.lives);


		this.platform.gamestarted();

		let splashScreen = document.getElementById("image");
		if (splashScreen) {
			splashScreen.hidden = true;
		}
		this.fallingObjects.forEach((f) => f.destroy(true));
		this.fallingObjects.splice(0, this.fallingObjects.length);

		this.physics.resume();



		this.screenHasBeenTapped = true;
		this.playing = true;
		this.playSound("startGame");
		this.catcher?.appear();
		for (let i = 0; i < CatchGame.config.minFallingObjects; i++) {
			const delay =
				Math.random() *
					(CatchGame.config.spawnDelayMax -
						CatchGame.config.spawnDelayMin) +
				CatchGame.config.spawnDelayMin;
			setTimeout(() => {
				if (this.isGameOver) {
					return;
				}
				this.spawnFallingObject();
			}, delay * i);
		}
	}

	resetFallingObject(fallingObject: FallingObject2) {
		if (this.isGameOver) {
			return;
		}
		fallingObject.kill();
		const delay =
			Math.random() *
				(CatchGame.config.spawnDelayMax -
					CatchGame.config.spawnDelayMin) +
			CatchGame.config.spawnDelayMin;

		setTimeout(() => {
			if (this.isGameOver) {
				return;
			}
			const rando = CatchGame.config.objects[Math.round((CatchGame.config.objects.length - 1)  * Math.random())];
			fallingObject.reset(rando);
		}, delay);
	}

	spawnFallingObject() {
		if (this.isGameOver) {
			return;
		}
		const rando = CatchGame.config.objects[Math.round((CatchGame.config.objects.length - 1)  * Math.random())];

		const fallingObject = new FallingObject2(rando, this);
		this.fallingObjects.push(fallingObject)
	}

	updateScore(plus: number) {
		this.score += plus;
	
		this.platform.sendScore(this.score);
	}

	loseLife() {
		if(this.catcher?.isFlickering){
			return;
		}
		this.catcher?.flicker();
		this.lives -= 1;
		this.platform.sendLives(this.lives);
	}

	// showHeartsAndScore() {
	// 	document.getElementById("score")!.style.transform = "translateY(0)";
	// 	document.getElementById(
	// 		"score"
	// 	)!.style.transition = `linear transform ${CatchGame.config.catcherBounceDownTime}ms`;

	// 	this.hearts.forEach((heart) => {
	// 		this.tweens.add({
	// 			targets: [heart],
	// 			y: {
	// 				to: 10,
	// 				from: -heart.displayHeight - 10,
	// 			},
	// 			ease: "linear",
	// 			duration: CatchGame.config.catcherBounceDownTime,
	// 		});
	// 	});
	// }

	restart() {
		this.maxObjects = CatchGame.config.minFallingObjects;
		this.fallingObjects.forEach((f) => f.destroy(true));

		this.speed = 1;
		this.maxObjects = CatchGame.config.minFallingObjects;
		this.spawnTimer = 0;
		this.objectCountIncreaseTimer = 0;
		this.speedIncreaseTimer = 0;

		this.particles.forEach((p) => {
			p.destroy()
		})

		this.particles = [];

		CatchGame.config.objects.forEach((object, i) => {
			const p = this.add.particles(`object${i}`)
			this.particles.push(p);
		})

		this.catcherEnabled = true;

		this.tweens.killAll();

		this.score = 0;
		this.updateScore(0);

		if (this.catcher) {
			this.catcher.reset();
		}

		let splashScreen = document.getElementById("image");
		if (splashScreen) {
			splashScreen.hidden = false;
		}
		this.screenHasBeenTapped = false;

		this.lives = CatchGame.config.lives;

	

		this.catcher?.reset();

		this.platform.ready();
	}

	isGameOver: boolean = false;

	gameOver() {
		this.playing = false;
		this.isGameOver = true;
		this.platform.gameover(Math.round(this.score));
	}

	handleCatch(faller: Phaser.Types.Physics.Arcade.GameObjectWithBody) {
		if (this.playing === false) {
			return;
		}
		const fallingObject: FallingObject2 = <FallingObject2>faller;
		
		if(fallingObject.invisible){
			return;
		}
		
		if (this.catcher) {
			if (this.tweens.isTweening(this.catcher)) {
				this.tweens.add({
					targets: [this.catcher],

					y: {
						to: this.catcher.startY,
						from:
							this.catcher.startY +
							CatchGame.config.catcherBounceDown,
					},
					ease: "in",
					duration: CatchGame.config.catcherBounceDownTime,
				});
			} else {
				this.tweens.add({
					targets: [this.catcher],

					y: {
						from: this.catcher.startY,
						to:
							this.catcher.startY +
							CatchGame.config.catcherBounceDown,
					},
					ease: "in",
					duration: CatchGame.config.catcherBounceDownTime,
					onComplete: () => {
						this.tweens.add({
							targets: [this.catcher],

							y: {
								to: this.catcher!.startY,
								from:
									this.catcher!.startY +
									CatchGame.config.catcherBounceDown,
							},
							ease: "in",
							duration: CatchGame.config.catcherBounceDownTime,
						});
					},
				});
			}
		}

		if (!fallingObject.config.doCatch) {
			this.playSound("lostLife");
			this.loseLife();
			fallingObject.kill();
			if (CatchGame.config.freezeOnMistake) {
				this.pause();
				setTimeout(() => {
					this.resetFallingObject(fallingObject);
					this.resume();
				}, CatchGame.config.freezeTime);
				return;
			}
			this.resume();
			this.resetFallingObject(fallingObject);
			return;
		}
		if (
			CatchGame.config.scoreType === "points" ||
			CatchGame.config.scoreType === "timeandpoints"
		) {
			this.updateScore(fallingObject.config.points);
		}
		this.playSound("caught");
		this.resetFallingObject(fallingObject);
		return;
	}

	hearts: Phaser.GameObjects.Image[] = [];

	drawHearts() {
		// const scale = CatchGame.getAspectScale();
		// this.hearts.forEach((h) => h.destroy());
		// for (let i = 0; i < this.lives; i++) {
		// 	const heart = this.add.image(0, 0, "heart");
		// 	const width = heart.width;
		// 	heart.setScale(
		// 		scale.x * CatchGame.config.heartScale,
		// 		scale.y * CatchGame.config.heartScale
		// 	);
		// 	heart.setOrigin(1, 0);
		// 	heart.setPosition(
		// 		CatchGame.width - 10 - i * (width * heart.scaleX * 1.2),
		// 		10
		// 	);
		// 	this.hearts.push(heart);
		// }
	}

	static getAspectScale() {
		const scaleX = CatchGame.width / 320;
		const scaleY = CatchGame.height / 500;
		if (scaleY < scaleX) {
			return { x: scaleY, y: scaleY };
		} else if (scaleX < scaleY) {
			return { x: scaleX, y: scaleX };
		} else {
			return { x: scaleX, y: scaleX };
		}
	}

	static getScale() {
		const scaleX = CatchGame.width / 320;
		const scaleY = CatchGame.height / 500;
		return { x: scaleX, y: scaleY };
	}

	playSound(soundKey: string, loop: boolean = false) {
		if (this.isGameOver) {
			return;
		}
		const config = CatchGame.config as any;
		if (config[soundKey + "Sound"])
			this.platform.playSound(config.path + config[soundKey + "Sound"]);
	}
}