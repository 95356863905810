import { CatchGame } from "./game";

export class Catcher extends Phaser.Physics.Arcade.Image {
    startY: number;
    game: CatchGame;
    constructor(scene: CatchGame){
        super(scene, CatchGame.width / 2, CatchGame.height - (CatchGame.config.catcherY / 100 * CatchGame.height), "catcher");

        this.game = scene;
        this.scene.physics.add.existing(this);
        this.scene.add.existing(this);
        this.setGravity(0);
        // this.setImmovable(true);
        this.setOrigin(0.5, 0.5);
        this.setBodySize(Math.round(this.width * CatchGame.config.catcherCollisionScaleX), Math.round(this.height * CatchGame.config.catcherCollisionScaleY), false);
        this.setDebugBodyColor(0x1C64EB);
        this.setOffset(this.width * (1-CatchGame.config.catcherCollisionScaleX) / 2, 0);

        const scale = CatchGame.getAspectScale();
        this.setScale(scale.x * CatchGame.config.catcherScale);
        this.startY = this.y + this.displayHeight * (CatchGame.config.catcherY / 100) - this.displayHeight/2
        this.setY(CatchGame.height + this.displayHeight/2)
        this.setDepth(CatchGame.config.catcherZ);
    }

    reset(){
        this.setAlpha(1);
        this.setY(CatchGame.height + this.displayHeight/2)
    }

    appear(){
        this.setX(CatchGame.width / 2);
        this.scene.tweens.add({
            targets: [this],
            y: {
                to: this.startY,
                from: CatchGame.height + this.displayHeight/2
            },
            ease: 'linear',
            duration: CatchGame.config.catcherBounceDownTime,
        });
    }

    lastPosition: number = CatchGame.width/2;
    resetRotation: any;

    updatePosition(mouseX: number){
        clearTimeout(this.resetRotation);

        // const scale = CatchGame.getScale();
        const xMax = CatchGame.width - this.body.width /2;
        const xMin = this.body.width / 2;
        this.x = Phaser.Math.Clamp(mouseX, xMin, xMax);


        const maxRotation = CatchGame.config.catcherMaxRotation;

        const dX = (this.x - this.lastPosition) * maxRotation/10;

        if(dX < 0){
            CatchGame.config.catcherFlip ? this.setFlipX(true) : null;

            this.setAngle(360-Phaser.Math.Clamp(Math.abs(dX), 0, maxRotation))

        } else if(dX > 0){
            CatchGame.config.catcherFlip ? this.setFlipX(false) : null;

            this.setAngle(Phaser.Math.Clamp(dX, 0, maxRotation))
        }

        this.resetRotation = setTimeout(() => {
            this.setAngle(0)
        }, 50)


        this.lastPosition = this.x;
    }

    isFlickering: boolean = false;

    flicker(){
        this.isFlickering = true;
        this.scene.tweens.killTweensOf(this);
        this.scene.add.tween({
            targets: this,
            yoyo: false,
            alpha: { from: 0, to: 1 },
            duration: CatchGame.config.freezeTime / CatchGame.config.flickerAmount,
            loop: CatchGame.config.flickerAmount,
          
        })
        setTimeout(() => {
            this.isFlickering = false;
        }, CatchGame.config.freezeTime)
    }
}
